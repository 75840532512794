import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-10 px-3mb-8 text-gray-800">
    <div className=" mx-auto ">
      <p className="text-center">&copy; {new Date().getFullYear()} Vetlight</p>
    </div>
  </footer>
);

export default Footer;
